/* eslint-disable import/prefer-default-export */

import { CmsPageFields } from 'Query/CmsPage.type';

import { CmsActionType, UpdateCmsPageAction, UpdateCmsPageLoadingAction } from './Cms.type';

/** @namespace PlugAndSell2/Store/Cms/Action/updateCmsPage */
export const updateCmsPage = (cmsPage: CmsPageFields): UpdateCmsPageAction => ({
    type: CmsActionType.UPDATE_CMS_PAGE,
    cmsPage,
});

/** @namespace PlugAndSell2/Store/Cms/Action/updateCmsPageLoading */
export const updateCmsPageLoading = (isLoading: boolean): UpdateCmsPageLoadingAction => ({
    type: CmsActionType.UPDATE_CMS_PAGE_LOADING,
    isLoading,
});

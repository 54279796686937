import { PureComponent } from 'react';

import CloseIcon from 'Component/CloseIcon';
import { ReactElement } from 'Type/Common.type';

import { InstallPromptAndroidComponentProps } from './InstallPromptAndroid.type';

import './InstallPromptAndroid.style';

/** @namespace PlugAndSell2/Component/InstallPromptAndroid/Component */
export class InstallPromptAndroidComponent extends PureComponent<InstallPromptAndroidComponentProps> {
    renderCloseButton(): ReactElement {
        const { handleBannerClose } = this.props;

        return (
            <button block="InstallPromptAndroid" elem="Close" onClick={handleBannerClose} aria-label={__('Close')}>
                <CloseIcon />
            </button>
        );
    }

    renderContent(): ReactElement {
        return (
            <p block="InstallPromptAndroid" elem="Content">
                {__('Add website to your home screen for the full-screen browsing experience!')}
            </p>
        );
    }

    renderInstallButton(): ReactElement {
        const { handleAppInstall } = this.props;

        return (
            <button block="InstallPromptAndroid" elem="Button" mix={{ block: 'Button' }} onClick={handleAppInstall}>
                {__('Add to home screen')}
            </button>
        );
    }

    render(): ReactElement {
        return (
            <div block="InstallPromptAndroid">
                {this.renderCloseButton()}
                {this.renderContent()}
                {this.renderInstallButton()}
            </div>
        );
    }
}

export default InstallPromptAndroidComponent;

import { PureComponent } from 'react';

import { ReactElement } from 'Type/Common.type';

import { InstallPromptIOSComponentProps } from './InstallPromptIOS.type';

import './InstallPromptIOS.style';

/** @namespace PlugAndSell2/Component/InstallPromptIOS/Component */
export class InstallPromptIOSComponent extends PureComponent<InstallPromptIOSComponentProps> {
    renderCloseButton(): ReactElement {
        const { handleBannerClose } = this.props;

        return (
            <button block="InstallPromptIOS" elem="Close" onClick={handleBannerClose}>
                {__('Maybe later')}
            </button>
        );
    }

    renderContent(): ReactElement {
        return (
            <p block="InstallPromptIOS" elem="Content">
                <span>{__('Tap:')}</span>
                <span block="InstallPromptIOS" elem="Share" />
                <span>{__(', then')}</span>
                <span block="InstallPromptIOS" elem="Plus" />
                <span>{__('Add to Home Screen')}</span>
            </p>
        );
    }

    renderHeading(): ReactElement {
        return (
            <p block="InstallPromptIOS" elem="Heading">
                {__('Browse website in full-screen:')}
            </p>
        );
    }

    render(): ReactElement {
        return (
            <div block="InstallPromptIOS">
                {this.renderHeading()}
                {this.renderContent()}
                {this.renderCloseButton()}
            </div>
        );
    }
}

export default InstallPromptIOSComponent;
